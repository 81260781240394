import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature1 from "components/hero/TwoColumnWithPrimaryBackground";
import MainFeature2 from "components/features/TwoColWithButton.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import hero_about_us from "images/hero_about_us.png";
import customer_matters from "images/where_customer_matters.png"


import SupportIconImage from "images/reliable-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import { Link } from 'react-scroll';

const Subheading_ = tw.span`uppercase tracking-wider text-sm text-custom_primary`;
export default () => {
  const teamRef = React.useRef(null);  

  return (
    <AnimationRevealPage disabled>
      {/* <Header /> */}
      <MainFeature1
        subHeading="About Shrimmp"
        heading="Driving AI Innovation with Precision and Excellence."
        description="Founded with a passion for innovation and excellence, our company has grown into a leading provider of data labeling and annotation services. We understand the critical role that high-quality data plays in the success of AI and machine learning models. Our journey began with a simple mission: to help businesses harness the power of accurate and reliable data to drive their AI initiatives forward."
        buttonRounded={false}
        onClickPrimary={() => teamRef.current.scrollIntoView({ behavior: 'smooth' })}
        primaryButtonText={<Link to="our-team" spy={true} smooth={true} offset={-70} duration={500}>Meet Our Team</Link>
        }
        imageSrc={hero_about_us}
      />
      <MainFeature2
        subheading={<Subheading_>Our Vision</Subheading_>}
        heading='Empowering AI with Data Annotation '
        description="Our vision is to empower businesses with highest quality data annotation services. We strive to be the trusted partner for companies seeking to transform raw data into valuable insights, driving innovation and growth in the AI landscape. By providing precise and efficient data labeling solutions, we enable our clients to develop robust AI models that perform with accuracy and reliability."
        description2=""
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc={customer_matters}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading_>Our Values</Subheading_>}
        heading="We follow these."
        description="Guiding Principles That Define Our Approach"
        cards={[
          {
            imageSrc: ShieldIconImage,
            title: "Quality",
            description: "We are committed to delivering the highest standards of quality in every project we undertake. Precision and accuracy are at the core of our services."
          },
          {
            imageSrc: SupportIconImage,
            title: "Precision",
            description: "Our advanced tools and methodologies guarantee precise and accurate data for your AI models."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Focus",
            description: "Our client's success is our success. We prioritize understanding their needs and providing tailored solutions that meet their unique requirements."
          },
        ]}
        linkText=""
      />
      <div id="our-team" ref={teamRef}>
        <TeamCardGrid 
          subheading={<Subheading_>Our Team</Subheading_>}
        />
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};
