import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

// import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

// import SupportIconImage from "../../images/support-icon.svg";
// import ShieldIconImage from "../../images/shield-icon.svg";
// import CustomizeIconImage from "../../images/customize-icon.svg";
// import FastIconImage from "../../images/fast-icon.svg";
// import ReliableIconImage from "../../images/reliable-icon.svg";
// import SimpleIconImage from "../../images/simple-icon.svg";

import { Database, Files, Brain, Pickaxe, BookOpenText, ImagePlay} from "lucide-react";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-custom_primary rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-custom_primary text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-custom_primary`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      Icon: ImagePlay,
      title: "Image & Video Annotation",
      description: "Enhance your visual data models with accurate image and video annotations. We specialize in object detection, segmentation, and classification to ensure your AI performs at its best."
    },
    { Icon: Files, title: "Data Curation", description: "Turn your raw data into valuable assests with our comprehensive data curation services. We organize, clean, and prepare your data for seamless integration into your AI workflows." },
    { Icon: Brain, title: "Generative AI", description: "Unlock the power of Generative AI with our enterprise-focused solutions. We help modern eterprises transform their raw data into intelligent, business-ready data through advanced labeling and annotation techniques." },
    { Icon: Pickaxe, title: "Web Scrapping", description: "Efficiently gather data from the web with our advanced web scraping services. We extract and structure data from various sources, providing you with the information you need." },
    { Icon: BookOpenText, title: "Audio & Text Annoation", description: "Improve your natural langauage processing models with precise audio and text annotations. Our services cover transcription, sentiment analysis, entity recognition and more, ensuring high-quality training datasets for your AI applications."},
    { Icon: Database, title: "Data Collection", description: "Get the right data for your AI projects with our custom data collection services. We source and collect data tailored to your specific requirements, ensuring quality and relevance." }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading tw="text-custom_primary">Our Professional <span tw="text-custom_secondary">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <card.Icon />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
