import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/ThreeColCenteredStatsPrimaryBackground";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/SimpleFiveColumn.js";
import heroScreenshotImageSrc from "images/hero-img-1.png";
import macHeroScreenshotImageSrc from "images/hero_gif_2.gif";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-custom_primary`;
  const HighlightedText = tw.span`text-custom_secondary`;

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
      }
    } else {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    }
  }, [location,]);

  return (
    <AnimationRevealPage disabled>
      <Hero 
        roundedHeaderButton={true} 
        primaryButtonUrl="/contact-us"
      />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={false}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>METHODS</Subheading>}
        heading={
          <>
            How We <HighlightedText>Work</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        heading={
          <>
            Industries We <HighlightedText>Serve</HighlightedText>
          </>
        }
        // imageSrc={prototypeIllustrationImageSrc}
        // showDecoratorBlob={false}
        // features={[
        //   {
        //     Icon: MoneyIcon,
        //     title: "Affordable",
        //     description: "We promise to offer you the best rate we can - at par with the industry standard.",
        //     iconContainerCss: tw`bg-green-300 text-green-800`
        //   },
        //   {
        //     Icon: BriefcaseIcon,
        //     title: "Professionalism",
        //     description: "We assure you that our templates are designed and created by professional designers.",
        //     iconContainerCss: tw`bg-red-300 text-red-800`
        //   }
        // ]}
        
      />
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      <section id="faq">
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is data annotation and why is it important for AI?",
            answer:
              "Data annotation involves labeling data to make it understandable for AI models. This process is crucial for training AI algorithms to recognize patterns, make decisions, and improve accuracy in tasks such as image recognition, speech recognition, natural language processing and predictive analysis."
          },
          {
            question: "What types of data services do you provide?",
            answer:
              "We offer a wide range of data services, including image and video annotation, audio and text annotation, data curation, generative AI solutions, web scraping, and custom data collection. Each service is designed to meet the specific needs of your AI projects."
          },
          {
            question: "How do you ensure the quality and accuracy of your annotations?",
            answer:
              "We use advanced tools and methodologies to ensure precision in our annotations. Our team of experts undergoes rigorous training, and we implement quality control measures throughout the annotation process to maintain high accuracy and consistency."
          },
          {
            question: "Can you handle large-scale data annotation projects?",
            answer:
              "Yes, we are equipped to handle projects of all sizes, Our scalable solutions ensure that we can meet the demands of large-scale data annotation projects, providing you with the data you need to develop and enhance your AI models."
          },
          {
            question: "How quickly can you deliver annotated data?",
            answer:
              "Our turnaround times vary depending on the project's complexity and size. However, we are committed to delivering high-quality annotated data as quickly and efficiently as possible, ensuring your projects stay on schedule."
          },
        ]}
      />
      </section>
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
