import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithButton.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import GetStarted from "components/cta/GetStarted";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import hero_img_8 from "images/Hero_img_8.png";
import hero_img_10 from "images/Hero_img_10.png";
import hero_img_11 from "images/Hero_img_11.png";
import hero_img_12 from "images/Hero_img_12.png";

import accuracyIcon from "images/accuracy-icon.svg";
import enhanceIcon from "images/enhance-icon.svg";
import businessInsightsIcon from "images/business-insights-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium text-custom_secondary`;
  const HighlightedText = tw.span`bg-custom_secondary text-white px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-custom_secondary px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage disabled>
      <Hero
        heading={<>Discover How Our Data Labeling Services<HighlightedText>Transform AI Models.</HighlightedText></>}
        description="Understanding the importance of high-quality data labeling is crucial for the success of your AI projects. Our services provide the foundation for robust and reliable AI models, ensuring that your business can leverage the full potential of AI technologies. Explore our use cases to see how our solutions can benefit various industries and applications."
        imageSrc={hero_img_8}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact-us"
        watchVideoButtonText=""
      />
      <MainFeature
        subheading={<Subheading>Generative AI</Subheading>}
        heading={
          <>
            Transforming Industries with 
            <wbr /> <HighlightedText>Generative AI.</HighlightedText>
          </>
        }
        description={
          <Description>
            {"Generative AI can revolutionize various industries by automating content creation, enhancing product development, and accelerating drug discovery."}
            <br />
            <br />
            {"Our generative AI solutions provide businesses with high-quality, annotated data that fuels the creation of innovative products, personalized marketing materials, and new molecular structures for pharmaceuticals. By leveraging our precise data annotations, businesses can significantly reduce development time and costs while increasing creativity and efficiency."}
          </Description>
        }
        description2=""
        showButton={false}
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={hero_img_10}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      {/* <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>menu.</HighlightedText>
          </>
        }
      /> */}
      <Features
        heading={
          <>
            Why Data Labeling is Important for <br/><HighlightedText>Your AI Model.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: accuracyIcon,
            title: "Ensure Accuracy",
            description: "High-quality data labeling is the cornerstone of accurate and reliable AI models.",
          },
          {
            imageSrc: enhanceIcon,
            title: "Enhance Model Training",
            description: "Accurate annotations enable more effective training of AI models, leading to better performance.",
  
          },
          {
            imageSrc: businessInsightsIcon,
            title: "Drive Business Insights",
            description: "Well-labeled data provides valuable insights that can drive strategic business decisions.",
          }
        ]}

        imageContainerCss={tw`p-4!`}
        imageCss={tw`w-[48px]! h-[48px]!`}
      />
      <MainFeature2
        subheading={<Subheading>Image And Video Annotation</Subheading>}
        heading={<>Optimizing Visual Data for AI Models</>}
        description="High-quality image and video annotations are essential for training AI models in industries such as autonomous vehicles, healthcare, and retail. Our precise annotations improve object detection, lane detection, and predestrian recognition for self-driving cars."
        description2="Our services assist in diagnosing medical conditions through accurate image labeling, and help retailers analyze customer behavior to optimize store layouts. We ensure that your visual data is accurately labeled, enabling your AI models to perform with exceptional accuracy and reliability."
        showButton={false}
        imageInsideDiv={false}
        imageSrc={hero_img_11}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <MainFeature2
        subheading={<Subheading>Audio And Text Annotation</Subheading>}
        heading={<>Enhancing NLP with Accurate Annotations</>}
        description="Accurate audio and text annotations are vital for developing AI models in industries such as speech recognition, natural language processing, and sentiment analysis. Our precise annotations improve the accuracy of speech-to-text conversion, language translation, and emotion detection in AI models."
        description2="Our services ensure that customer interactions are properly transcribed and analyzed, enabling chatbots and voice assistants to understand and respond accurately. Our annotations enhance the performance and accuracy of your Natural Langauage Processing (NLP) applications."
        showButton={false}
        imageInsideDiv={false}
        imageSrc={hero_img_12}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}
      {/* <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Treact App.</HighlightedTextInverse></>}
      /> */}
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
