import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import hero_gif_3 from "images/Hero_3.gif"
import hero_img_4 from "images/Hero_img_4.png"
import hero_img_5 from "images/Hero_img_5.png"
import hero_img_6 from "images/Hero_img_6.png"
import hero_img_7 from "images/Hero_img_7.gif"
import hero_gif_4 from "images/Hero_gif_4.gif"
import hero_gif_5 from "images/Hero_gif_5.gif"
import hero_gif_6 from "images/Hero_gif_6.gif"
import hero_gif_7 from "images/Data extraction.gif"
import hero_gif_8 from "images/Website Creator.gif"
import hero_gif_9 from "images/Data Collection.gif"
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import { Link } from 'react-scroll';

import QualityIcon from "../images/quality-icon.svg";
import PrecisionIcon from "../images/precision-icon.svg";
import CustomerFocusIcon from "../images/customer-focus.svg";
import GetStarted from "components/cta/GetStarted";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';




export default () => {
  const teamRef = React.useRef(null); 

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
      }
    } else {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    }
  }, [location,]);

  return (
    <AnimationRevealPage disabled>
      <Hero 
        heading = "Unlock the Full Potential of Your AI with Our Data Solutions"
        description="We deliver the best-trained data for all industries, ensuring your AI models are built on a foundation of high-quality data. Explore our comprehensive range of services and discover how we can help you transform raw data into valuable insights, driving innovation and growth in your AI initiatives."
        onClickPrimary={() => teamRef.current.scrollIntoView({ behavior: 'smooth' })}
        primaryButtonText={<Link to="our-services" spy={true} smooth={true} offset={-70} duration={500}>Our Services</Link>}
        imageSrc={hero_img_5}

      />
      <Features 
        heading="Why Choose Us"
        description="Our solutions are designed with a commitment to quality, precision, and customer focus, making us the trusted partner for your data needs."
        cards = {[
          {
            imageSrc: QualityIcon,
            title: "Quality",
            description: "We are commited to delivering the highest standards of quality in every project.",
            alt: "Quality Icon",
          },
          { imageSrc: PrecisionIcon, 
            title: "Precision",
            description: "Our advanced tools and methodologies guarantee precise and accurate data for your AI models.",
            alt: "Precision Icon",
          },
          { imageSrc: CustomerFocusIcon, 
            title: "Customer Focus" ,
            description: "Your success is our success. We prioritize understanding your needs and providing tailored solutions.",
            alt: "Customer Icon",
          },
        ]}
      />
      {/* <Pricing /> */}
      <div id="our-services" ref={teamRef}>
      <section id="image-video">
      <MainFeature 
        subheading="Image and Video Annotation"
        heading="Enhance Your Visual AI Models with High-Quality Annotations"
        description="Our image and video annotation services ensure that your visual data is accurately labeled, enabling your AI models to perform with exceptional accuracy. We specialize in object detection, segmentation, and classification, providing detailed and precise annotations. Whether you need bounding boxes, polygons, or semantic segmentation, our team delivers high-quality annotations tailored to your specific needs."
        description2=""
        showButton={false}
        imageSrc={hero_gif_3}
        buttonRounded={false}
      />
      </section>
      <section id="genAI">
      <MainFeature 
        subheading="Generative AI"
        heading="Empower Your Enterprise with Advanced Generative AI Solutions"
        description="Leverage the power of generative AI to create new, meaningful data from existing datasets. Our generative AI solutions help modern enterprises transform raw data into intelligent, business-ready data. We provide comprehensive data labeling and annotation services that support the development of generative models, enabling you to drive innovation, automate processes, and gain valuable business insights."
        description2=""
        showButton={false}
        imageSrc={hero_img_6}
        buttonRounded={false}
        textOnLeft={false}
      />
      </section>
      <section id="audio-text">
      <MainFeature 
        subheading="Audio and Text Annotation"
        heading="Improve NLP models with Accurate Audio and Text Annotations"
        description="Enhance your natural language processing (NLP) models with our precise audio and text annotation services. We offer transcription, sentiment analysis, entity recognition, and more. Our team ensures that your audio and text data is accurately labeled, improving the performance and accuracy of your NLP models. Whether you need detailed transcription or nuanced sentiment annotations, we deliver high-quality results."
        description2=""
        showButton={false}
        imageSrcs={[hero_gif_4, hero_gif_5]}
        isCarousel={true}
        buttonRounded={false}
      />
      </section>
      <section id="data-curation">
      <MainFeature 
        subheading="Data Curation"
        heading="Transform Raw Data into Valuable Insights"
        description="Data curation is essential for creating high-quality datasets that drive AI success. Our data curation services involve organizing, cleaning, and preparing your data to ensure it is ready for analysis and model training. We meticulously handle data normalization, duplication removal, and quality checks to provide you with reliable and structured data, enhancing the performance of your AI systems."
        description2=""
        showButton={false}
        imageSrc={hero_gif_7}
        buttonRounded={false}
        textOnLeft={false}
      />
      </section>
      <section id="web-scrapping">
      <MainFeature 
        subheading="Web Scraping"
        heading="Efficiently Gather and Structure Data from the Web"
        description="Our web scraping services enable you to collect data from various online sources efficiently. We extract and structure data from websites, ensuring it is ready for analysis and integration into your AI models. Our team handles everything from data extraction to cleaning and formatting, providing you with high-quality, usable data that meets your specific requirements."
        description2=""
        showButton={false}
        imageSrc={hero_gif_8}
        buttonRounded={false}
        textOnLeft={true}
      />
      </section>
      <section id="data-collection">
      <MainFeature 
        subheading="Data Collection"
        heading="Get the right Data for Your AI Projects"
        description="Our custom data collections services ensure you have the exact data you need for your AI projects. We source and collect data tailored to your specific requirements, providing high-quality and relevant datasets. Our team handles everything from survey design to data gathering and validation, ensuring that the data we provide meets the highest standards of quality and precision."
        description2=""
        showButton={false}
        imageSrc={hero_gif_9}
        buttonRounded={false}
        textOnLeft={false}
      />
      </section>
      </div>
      {/* <Testimonial />
      <FAQ /> */}
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
}
