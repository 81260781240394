import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/shrimmp_rectangle.svg";
// import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as InstagramIcon} from "../../images/instagram-icons-48.svg"



const Container = tw.div`relative bg-custom_primary -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-white`;

const LinkList = tw.ul`mt-4 text-sm font-medium `;
const LinkListItem = tw.li`mt-3 text-white`;
const Link = tw.a`border-b-2 border-transparent hocus:text-custom_secondary hocus:border-custom_secondary pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 text-white`;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-white text-gray-800 hover:bg-custom_secondary hover:text-white transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} style={{width: '360px', height: '90px'}} />
            <LogoText></LogoText>
          </LogoContainer>
          <CompanyDescription>
            Shrimmp is the best and highest-quality data labeling services company. We are helping companies ship AI faster.
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://www.linkedin.com/company/shrimmp-ai/" target="_blank" rel="noopener noreferrer">
              <LinkedinIcon />
            </SocialLink>
            <SocialLink href="https://x.com/Shrimmpai" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </SocialLink>
            {/* <SocialLink href="https://instagram.com">
              <InstagramIcon />
            </SocialLink> */}
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Solutions</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/solution#image-video">Image & Video Annotation</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/solution#data-curation">Data Curation</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/solution#genAI">Generative AI</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/solution#web-scrapping">Web Scraping</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/solution#audio-text">Audio & Text Annotation</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/solution#data-collection">Data Collection</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Resources</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/blog">Blogs</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/#faq">FAQ</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/about-us">About Us</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Contact Us</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">contact@shrimmp.com</Link>
            </LinkListItem>
            {/* <LinkListItem>
              <Link href="#">sales@shrimmp.com</Link>
            </LinkListItem> */}
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
