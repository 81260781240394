import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import shrimmp_purple_logo from "images/shrimmp_rectangle_purple.svg";
import styled from "styled-components";

const Address = tw.span`leading-relaxed text-center`;
const AddressLine = tw.span`block text-center`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-custom_secondary hocus:text-custom_secondary
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! bg-transparent`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header
        logoLink={<LogoLink href="/"> <img src={shrimmp_purple_logo} style={{width: '240px', height: '60px'}} alt="logo" /></LogoLink>}
        bgColor = "custom_primary" 
      />
      <ContactUsForm 
        description="Accelerate Your AI Product Development, With Shrimmp Data Services. Let Us Drive Your AI Vision Forward."
      />
      <ContactDetails
        cards={[
          {
            title: "Bengaluru",
            description: (
              <>
                <Address>
                  <AddressLine>Nikoo Homes 1</AddressLine>
                  <AddressLine>Bengaluru, Karnataka 560064</AddressLine>
                </Address>
                <Email>bhatishriomsingh@gmail.com</Email>
                <Phone>+91-9828994655</Phone>
              </>
            )
          },
        ]}
        description="Feel free to reach out to us for any queries. We are happy to help you."
      />
      <Footer />
    </AnimationRevealPage>
  );
};
