// import React from "react";
// import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
// import { SectionDescription } from "components/misc/Typography";

// const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
// const HeadingContainer = tw.div``;
// const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
// const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
// const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

// const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
// const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
// const StatKey = tw.div`text-xl font-medium`
// const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

// export default ({
//   subheading = "",
//   heading = "Over 9000 Projects Completed",
//   description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   stats = [
//     {
//       key: "Clients",
//       value: "2500+",
//     },
//     {
//       key: "Revenue",
//       value: "$100M+",
//     },
//     {
//       key: "Employees",
//       value: "150+",
//     },
//   ]
// }) => {
//   return (
//     <Container>
//       <ContentWithPaddingXl>
//         <HeadingContainer>
//           {subheading && <Subheading>{subheading}</Subheading>}
//           <Heading>{heading}</Heading>
//           {description && <Description>{description}</Description>}
//         </HeadingContainer>
//         <StatsContainer>
//           {stats.map((stat, index) => (
//             <Stat key={index}>
//               <StatValue>{stat.value}</StatValue>
//               <StatKey>{stat.key}</StatKey>
//             </Stat>
//           ))}
//         </StatsContainer>
//       </ContentWithPaddingXl>
//     </Container>
//   );
// };


import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-custom_primary text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer =  tw.div`mt-4 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-center mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide bg-white hover:bg-custom_secondary hover:text-white text-secondary-400 rounded-lg m-2`
const StatKey = tw.div`text-xl font-medium`

export default ({
  subheading = "",
  heading = "Industries We Serve",
  description = "We provide services to a wide range of industries. Here are some of them:",
  stats = [
    {
      key: "Conversational AI",
    },
    {
      key: "Logistics & Supply Chain",
    },
    {
      key: "Retail & Ecommerce",
    },
    {
      key: "Automotive",
    },
    {
      key: "Real Estate",
    },
    {
      key: "Robotics",
    },
    {
      key: "Logistics & Supply Chain",
    },
    {
      key: "Warehousing & Inventory Management",
    },
    {
      key: "Healthcare ",
    },
    {
      key: "Banking and Finance",
    },
    {
      key: "Manufacturing",
    },
    {
      key: "Augmented Reality",
    },
    {
      key: "Intelligent Document Processing ",
    },
    {
      key: "And Many More...",
    },
    // Add more industries as needed
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};



// Agriculture
// Construction
// Manufacturing
// Geospatial
// Sports & Fitness
// Security & Surveillance

